<template>
  <div class="bg-white p-2 border rounded shadow-sm" v-if="subscriptionDetail">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3>Subscription Attributes</h3>
        <p>
          <strong>Payment Type:&nbsp;</strong>
          <span
            v-if="subscriptionDetail.insertionOrder.installmentquantity > 1"
          >
            Installments, billed every
            {{ subscriptionDetail.insertionOrder.installmentduration }} months
            for a total of
            {{
              subscriptionDetail.insertionOrder.installmentquantity *
              subscriptionDetail.insertionOrder.installmentduration
            }}
            months
          </span>
          <span v-else>
            Subscription, recurrs every
            {{ subscriptionDetail.insertionOrder.installmentduration }} months
            until cancelled
          </span>
        </p>
      </div>
      <div>
        <button
          class="btn btn-primary"
          @click.prevent="
            sync = true;
            syncType = 'sync';
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-refresh"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4"></path>
            <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4"></path>
          </svg>

          Sync
        </button>
      </div>
    </div>

    <synchronize
      v-if="sync"
      :subscriptionId="subscriptionDetail.subscription.subscriptionid"
      :syncType="syncType"
      @cancel="sync = false"
    ></synchronize>
    <table class="table">
      <thead>
        <tr>
          <th>Attribute</th>
          <th>Expected</th>
          <th>Actual</th>
          <th>Match</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Subscription Start Date</td>
          <td>
            {{
              prettyDate(
                subscriptionDetail.expectedSubscriptionValues
                  .subscriptionStartDate
              )
            }}
          </td>
          <td>{{ prettyDate(subscriptionDetail.subscriptionStartDate) }}</td>
          <td>
            <match-icon
              :date="
                subscriptionDetail.expectedSubscriptionValues
                  .subscriptionStartDate
              "
              :comparisonDate="subscriptionDetail.subscriptionStartDate"
            ></match-icon>
          </td>
        </tr>
        <tr>
          <td>Previous Payment Date</td>
          <td>
            {{
              prettyDate(
                subscriptionDetail.expectedSubscriptionValues
                  .previousPaymentDate
              )
            }}
          </td>
          <td>{{ prettyDate(subscriptionDetail.subscriptionLastCharge) }}</td>
          <td>
            <match-icon
              :date="
                subscriptionDetail.expectedSubscriptionValues
                  .previousPaymentDate
              "
              :comparisonDate="subscriptionDetail.subscriptionLastCharge"
            ></match-icon>
          </td>
        </tr>
        <tr>
          <td>Next Payment Date</td>
          <td>
            {{
              prettyDate(
                subscriptionDetail.expectedSubscriptionValues.nextPaymentDate
              )
            }}
          </td>
          <td>{{ prettyDate(subscriptionDetail.subscriptionNextCharge) }}</td>
          <td>
            <match-icon
              :date="
                subscriptionDetail.expectedSubscriptionValues.nextPaymentDate
              "
              :comparisonDate="subscriptionDetail.subscriptionNextCharge"
            ></match-icon>
          </td>
        </tr>
      </tbody>
    </table>

    <h3>Authnet Transactions</h3>
    <table class="table">
      <thead>
        <tr>
          <th>Transaction ID</th>
          <th>Date</th>
          <th>Response</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="transaction in subscriptionDetail.expectedSubscriptionValues
            .transactions"
          :key="`transaction-${transaction.transactionId}`"
        >
          <td>{{ transaction.transactionId }}</td>
          <td>{{ prettyDate(transaction.date) }}</td>
          <td>{{ transaction.response }}</td>
        </tr>
      </tbody>
    </table>

    <h3>Insertion Order Items</h3>
    <table class="table">
      <thead>
        <tr>
          <th>Item</th>
          <th>Expected End</th>
          <th>Actual End</th>
          <th>Match</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in subscriptionDetail.insertionOrder.insertionOrderItems"
          :key="`io-${subscriptionDetail.insertionOrder.insertionorderid}-item-${item.id}`"
        >
          <td>{{ item.itemName }}</td>
          <td>
            {{
              prettyDate(subscriptionDetail.expectedSubscriptionValues.endDate)
            }}
          </td>
          <td>{{ prettyDate(item.actualEndDate) }}</td>
          <td>
            <match-icon
              :date="subscriptionDetail.expectedSubscriptionValues.endDate"
              :comparisonDate="item.actualEndDate"
            ></match-icon>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      v-if="
        subscriptionDetail.subscription &&
        subscriptionDetail.subscription.intervallength == 12
      "
    >
      <a
        href="#"
        @click.prevent="
          sync = true;
          syncType = 'convert';
        "
      >
        Convert this annual subscription to a standard invoice.
      </a>
    </div>
  </div>
</template>

<script>
import BillingService from "../../../services/BillingService";
import { format } from "date-fns";
import MatchIcon from "./MatchIcon.vue";
import Synchronize from "./Synchronize.vue";

export default {
  components: { MatchIcon, Synchronize },
  name: "admin.subscriptions.subscription-detail",
  data() {
    return {
      subscriptionDetail: null,
      sync: false,
      syncType: "sync",
    };
  },
  props: {
    subscription: Object,
    pullInfo: Boolean,
  },
  methods: {
    async loadSubscription() {
      this.subscriptionDetail = await BillingService.getSubscriptionDetail(
        this.subscription.subscription.subscriptionid
      );
    },
    prettyDate(date) {
      try {
        return format(new Date(date), "MM/dd/yyyy");
      } catch (e) {
        return "NULL";
      }
    },
  },
  mounted() {
    this.loadSubscription();
  },
};
</script>

<template>
  <div class="card mb-3 border-primary" v-if="reportResults">
    <div class="card-body">
      <h3>Proposed {{ syncType }} Changes:</h3>
      <table class="table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Old value</th>
            <th>Will become</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(change, idx) in reportResults.changes"
            :key="`change-${subscriptionId}-${idx}`"
          >
            <td>{{ change.path }}</td>
            <td>{{ change.oldValue }}</td>
            <td class="bg-success-lt text-success">{{ change.newValue }}</td>
          </tr>
        </tbody>
      </table>

      <div>
        <button
          class="btn btn-outline-danger me-4"
          @click.prevent="commit"
          v-if="!loading"
        >
          Commit Changes
        </button>
        <button class="btn btn-primary" @click.prevent="cancel">
          Cancel Changes
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BillingService from "../../../services/BillingService";

export default {
  name: "admin.subscriptions.synchronize",
  props: {
    subscriptionId: Number,
    syncType: String,
  },
  data() {
    return {
      reportResults: null,
      loading: false,
    };
  },
  watch: {
    syncType() {
      this.getSyncPreview();
    },
  },
  methods: {
    async getSyncPreview() {
      this.loading = true;
      if (this.syncType == "sync") {
        this.reportResults = await BillingService.fulfillSingleSubscriptionDry(
          this.subscriptionId
        );
      } else {
        this.reportResults = await BillingService.convertSubscriptionDry(
          this.subscriptionId
        );
      }
      this.loading = false;
    },
    cancel() {
      this.$emit("cancel");
    },
    async commit() {
      let confirm = await this.$bvModal.msgBoxConfirm(
        `Please confirm that you want to overwrite and ${this.syncType} this subscription.`,
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sync",
          cancelTitle: "Do not sync",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        }
      );

      if (confirm) {
        // perform sync
        //reload window
        if (this.syncType == "sync") {
          await BillingService.fulfillSingleSubscriptionActual(
            this.subscriptionId
          );
        } else {
          await BillingService.convertSubscriptionActual(this.subscriptionId);
        }
        window.location.reload();
      } else {
        this.$emit("cancel");
      }
    },
  },
  async mounted() {
    console.log(this.subscriptionId);
    this.getSyncPreview();
  },
};
</script>

<template>
  <svg
    v-if="match()"
    xmlns="http://www.w3.org/2000/svg"
    class="icon text-success"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke-width="2"
    stroke="currentColor"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="12" cy="12" r="9"></circle>
    <path d="M9 12l2 2l4 -4"></path>
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    class="icon text-warning"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke-width="2"
    stroke="currentColor"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M12 9v2m0 4v.01"></path>
    <path
      d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"
    ></path>
  </svg>
</template>

<script>
import { format } from "date-fns";

export default {
  props: {
    date: String,
    comparisonDate: String,
  },
  methods: {
    prettyDate(date) {
      try {
        return format(new Date(date), "MM/dd/yyyy");
      } catch (e) {
        return "NULL";
      }
    },
    match() {
      return (
        this.prettyDate(this.date) === this.prettyDate(this.comparisonDate)
      );
    },
  },
};
</script>

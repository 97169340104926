<template>
  <div class="home page-wrapper">
    <div class="container" v-if="loaded">
      <!-- Page title -->
      <div class="page-header d-print-none mb-3">
        <div class="row align-items-center">
          <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle">Administrative Reporting</div>
            <h2 class="page-title">Recurring Billing Dashboard</h2>
          </div>
          <div class="col text-end">
            <button class="btn btn-primary" @click="csv">Export</button>
          </div>
        </div>
      </div>

      <div class="row row-cards mb-3">
        <div class="col-sm-6 col-lg-3">
          <div class="card card-sm">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="bg-blue text-white avatar"
                    ><!-- Download SVG icon from http://tabler-icons.io/i/currency-dollar -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M16.7 8a3 3 0 0 0 -2.7 -2h-4a3 3 0 0 0 0 6h4a3 3 0 0 1 0 6h-4a3 3 0 0 1 -2.7 -2"
                      ></path>
                      <path d="M12 3v3m0 12v3"></path>
                    </svg>
                  </span>
                </div>
                <div class="col">
                  <div class="font-weight-medium">
                    {{ subscriptions.length }} recurring subscriptions
                  </div>
                  <div class="text-muted">
                    {{ awaitingFulfillment.length }} awaiting fulfillment
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-lg-3">
          <div class="card card-sm">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="bg-green text-white avatar"
                    ><!-- Download SVG icon from http://tabler-icons.io/i/shopping-cart -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <circle cx="6" cy="19" r="2"></circle>
                      <circle cx="17" cy="19" r="2"></circle>
                      <path d="M17 17h-11v-14h-2"></path>
                      <path d="M6 5l14 1l-1 7h-13"></path>
                    </svg>
                  </span>
                </div>
                <div class="col">
                  <div class="font-weight-medium">
                    {{ freeTrials.length }} ongoing free trials
                  </div>
                  <div class="text-muted">
                    {{ freeTrialsAwaitingFulfillment.length }} awaiting
                    fulfillment
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-lg-3">
          <div class="card card-sm">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="bg-twitter text-white avatar"
                    ><!-- Download SVG icon from http://tabler-icons.io/i/brand-twitter -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z"
                      ></path>
                    </svg>
                  </span>
                </div>
                <div class="col">
                  <div class="font-weight-medium">
                    {{ money(recurringDollars) }} monthly recurring charges
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="card card-sm">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="bg-warning text-white avatar">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M12 9v2m0 4v.01"></path>
                      <path
                        d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"
                      ></path>
                    </svg>
                  </span>
                </div>
                <div class="col">
                  <div class="font-weight-medium">
                    {{ outOfSync.length }} subscriptions out of sync
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <table class="table">
            <thead>
              <tr>
                <th>Company</th>
                <th>Authnet sub id</th>
                <th>Type</th>
                <th>Frequency</th>
                <th>Started</th>
                <th>Last Charge</th>
                <th>
                  <a
                    href="#"
                    @click.prevent="
                      sort = sort == 'nextAsc' ? 'nextDesc' : 'nextAsc'
                    "
                  >
                    Next charge
                  </a>
                </th>
                <th>Recurring Amt.</th>
                <th>Times billed</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="subscription in sorted">
                <tr
                  :key="`info-row-${subscription.subscription.subscriptionid}`"
                >
                  <td>
                    <a
                      :href="`https://salescore.mediabrains.com/callscreen.aspx?companyid=${subscription.company.companyid}`"
                      target="_blank"
                      >{{ subscription.company.companyname }}</a
                    >
                  </td>
                  <td>
                    <a
                      :href="`https://account.authorize.net/ui/themes/anet/ARB/SubscriptionDetail.aspx?SubscrID=${subscription.subscription.subscriptionid}`"
                      target="_blank"
                    >
                      {{ subscription.subscription.subscriptionid }}
                    </a>
                  </td>
                  <td>
                    <span
                      class="badge bg-azure"
                      v-if="subscription.insertionOrder.installmentquantity > 1"
                    >
                      I
                    </span>
                    <span class="badge bg-green" v-else> S </span>
                  </td>
                  <td>
                    every
                    {{ subscription.insertionOrder.installmentduration }} months
                  </td>
                  <td>
                    {{ prettyDate(subscription.subscriptionStartDate) }}
                  </td>
                  <td>
                    {{ prettyDate(subscription.subscriptionLastCharge) }}
                  </td>
                  <td>
                    {{ prettyDate(subscription.subscriptionNextCharge) }}
                  </td>
                  <td>
                    {{
                      money(
                        Math.round(
                          subscription.insertionOrder.totalamount /
                            subscription.insertionOrder.installmentquantity
                        )
                      )
                    }}
                  </td>
                  <td>{{ subscription.pastOccurences }}</td>
                  <td>
                    <span
                      class="badge bg-azure"
                      v-if="subscription.insertionOrder.trialOccurence == 1"
                      >Trial</span
                    >
                    <span class="badge bg-green" v-else>Paid</span>
                  </td>
                  <td>
                    <span
                      class="badge bg-red"
                      v-if="subscription.insertionOrder.needsFulfillment == 1"
                    >
                      Not Full
                    </span>
                    <span class="badge bg-green" v-else>Running</span>
                  </td>
                  <td>
                    <a
                      href="#"
                      @click.prevent="
                        subscription.showDetails = !subscription.showDetails
                      "
                    >
                      <svg
                        v-if="subscription.isOutOfSync"
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon text-warning"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M12 9v2m0 4v.01"></path>
                        <path
                          d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"
                        ></path>
                      </svg>
                      <svg
                        v-else
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon text-success"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <circle cx="12" cy="12" r="9"></circle>
                        <path d="M9 12l2 2l4 -4"></path>
                      </svg>
                    </a>
                  </td>
                </tr>
                <tr
                  :key="`detail-row-${subscription.subscription.subscriptionid}`"
                  v-if="subscription.showDetails"
                >
                  <td colspan="12">
                    <subscription-detail
                      :subscription="subscription"
                      :pullInfo="subscription.showDetails"
                    ></subscription-detail>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BillingService from "../../services/BillingService";
import { format, parseJSON, compareDesc, compareAsc } from "date-fns";
import SubscriptionDetail from "../../components/Admin/Subscriptions/SubscriptionDetail.vue";

import CSVService from "../../services/CSVService";

export default {
  name: "admin.recurring-billing",
  components: {
    SubscriptionDetail,
  },
  data() {
    return {
      subscriptions: [],
      loaded: false,
      sort: "nextDesc",
    };
  },
  computed: {
    sorted() {
      if (this.sort == "nextAsc") {
        return [...this.subscriptions].sort((a, b) => {
          if (!a.insertionOrder.installmentnextdate) {
            return -1;
          } else if (!b.insertionOrder.installmentnextdate) {
            return 1;
          }
          return compareAsc(
            parseJSON(a.insertionOrder.installmentnextdate),
            parseJSON(b.insertionOrder.installmentnextdate)
          );
        });
      } else if (this.sort == "nextDesc") {
        return [...this.subscriptions].sort((a, b) => {
          if (!a.insertionOrder.installmentnextdate) {
            return 1;
          } else if (!b.insertionOrder.installmentnextdate) {
            return -1;
          }
          return compareDesc(
            parseJSON(a.insertionOrder.installmentnextdate),
            parseJSON(b.insertionOrder.installmentnextdate)
          );
        });
      }
      return this.subscriptions;
    },
    awaitingFulfillment() {
      return this.subscriptions.filter(
        (s) => s.insertionOrder.needsFulfillment == 1
      );
    },
    freeTrials() {
      return this.subscriptions.filter(
        (s) => s.insertionOrder.trialOccurence == 1
      );
    },
    freeTrialsAwaitingFulfillment() {
      return this.freeTrials.filter(
        (s) => s.insertionOrder.needsFulfillment == 1
      );
    },
    outOfSync() {
      return this.subscriptions.filter((s) => s.isOutOfSync);
    },
    recurringDollars() {
      return Math.round(
        this.subscriptions.reduce(function (a, b) {
          return (
            a +
            b["insertionOrder"]["totalamount"] /
              b["insertionOrder"]["installmentquantity"] /
              b["insertionOrder"]["installmentduration"]
          );
        }, 0)
      );
    },
  },
  methods: {
    csv() {
      let data = this.sorted.map((subscription) => {
        return {
          Company: subscription.company.companyname,
          "Authnet sub id": subscription.subscription.subscriptionid,
          Type: subscription.insertionOrder.installmentquantity > 1 ? "I" : "S",
          FrequencyNumber: subscription.insertionOrder.installmentduration,
          FrequencyUnit: `months`,
          Started: this.prettyDate(subscription.subscriptionStartDate),
          "Last Charge": this.prettyDate(subscription.subscriptionLastCharge),
          "Next charge": this.prettyDate(subscription.subscriptionNextCharge),
          "Recurring Amt.": Math.round(
            subscription.insertionOrder.totalamount /
              subscription.insertionOrder.installmentquantity
          ),
          "Times billed": subscription.pastOccurences,
          Trial:
            subscription.insertionOrder.trialOccurence == 1 ? "Trial" : "Paid",
          Fulfillment:
            subscription.insertionOrder.needsFulfillment == 1
              ? "Not Fullfilled"
              : "Running",
        };
      });

      let csv = CSVService.serialize({
        fields: [
          { id: "Company", label: "Company" },
          { id: "Authnet sub id", label: "Authnet sub id" },
          { id: "Type", label: "Type" },
          { id: "FrequencyNumber", label: "Frequency Number" },
          { id: "FrequencyUnit", label: "Frequency Unit" },
          { id: "Started", label: "Started" },
          { id: "Last Charge", label: "Last Charge" },
          { id: "Next charge", label: "Next charge" },
          { id: "Recurring Amt.", label: "Recurring Amt." },
          { id: "Times billed", label: "Times billed" },
          { id: "Trial", label: "Trial" },
          { id: "Fulfillment", label: "Fulfillment" },
        ],
        records: data,
      });

      let download = document.createElement("a");
      let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      let url = URL.createObjectURL(blob);
      download.href = url;
      download.setAttribute("download", "billing_export.csv");
      download.click();
    },
    prettyDate(date) {
      try {
        return format(new Date(date), "MM/dd/yyyy");
      } catch (e) {
        return "NULL";
      }
    },
    async getSubscriptions() {
      let subscriptions = await BillingService.getSubscriptions();
      subscriptions.map((s) => (s.showDetails = false));
      this.subscriptions = subscriptions;
    },
    money(number) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(number);
    },
  },
  async mounted() {
    await this.getSubscriptions();
    this.loaded = true;
  },
};
</script>
